<script lang="ts">
	import { nbspify } from "../../../core/utils/nbspify.js";
	import arrowRight from "../../../core/assets/icons/arrowRight.svg?raw";
	import externalLink from "../../../core/assets/icons/external-link.svg?raw";
	import Icon from "./Icon.svelte";
	import StaticPicture from "./Picture/StaticPicture.svelte";
	import { flyInOnScroll } from "../utils/flyInOnScroll.js";
	import DynamicPicture from "./Picture/DynamicPicture.svelte";
	import type { Media } from "../../../core/schema/Media.js";
	import { querySizeMappings } from "../utils/querySizeMappings.js";

	export let index: number;
	export let image: Media | string | undefined = undefined;
	export let darkFilter = false;
	export let whiteOnHover = darkFilter;
	export let title: string;
	export let sectionName: string | undefined = undefined;
	export let link: string | undefined = undefined;
	export let disableAnimation = false;

	export let backgroundImage: Media | string | undefined = undefined;
	export let backgroundImageOverlay = false;
	export let fixedBgOnMobile = false;

	let containerClass = "";
	export { containerClass as class };
</script>

<a
	use:flyInOnScroll={{
		disable: disableAnimation,
		delay:
			window.innerWidth > querySizeMappings.xl
				? (index % 3) * 0.2
				: window.innerWidth > querySizeMappings.md
					? (index % 2) * 0.2
					: 0.2,
	}}
	href={link}
	target="_blank"
	class="bg-tertiary group relative aspect-square h-auto w-full min-w-[17.5rem] max-w-[36.25rem] {containerClass}"
>
	<div
		class="absolute {darkFilter &&
			fixedBgOnMobile &&
			'text-white lg:text-black'} inset-0 z-20 flex h-full w-full flex-col justify-between gap-1 p-5 transition-all duration-300 lg:px-[3.75rem] lg:pb-[4rem] lg:pt-[4.375rem] {backgroundImage !==
			undefined && whiteOnHover
			? backgroundImageOverlay
				? link
					? 'group-hover:lg:text-white'
					: 'group-hover:text-white'
				: 'text-white'
			: 'text-black'}"
	>
		<span class="md:text-2md text-[1.125rem] font-light">{sectionName}</span>
		{#if image}
			{#if typeof image === "string"}
				<StaticPicture
					{image}
					alt={title}
					width={320}
					height={320}
					class="relative flex-1"
					imgClass="object-contain absolute inset-0 !h-full m-auto transition-all {backgroundImageOverlay &&
						`${link ? 'group-hover:lg:opacity-0' : 'group-hover:opacity-0'} opacity-100`}"
				/>
			{:else}
				<DynamicPicture
					{image}
					alt={title}
					width={320}
					height={320}
					class="relative flex-1"
					imgClass="object-contain absolute inset-0 !h-full m-auto transition-all {backgroundImageOverlay &&
						`${link ? 'group-hover:lg:opacity-0' : 'group-hover:opacity-0'} opacity-100`}"
					bucket="storage"
				/>
			{/if}
		{/if}
		<div class="items-last-baseline flex justify-between gap-5">
			<p class="lg:text-2lg text-md mb-0 flex items-end font-bold">
				{nbspify(title)}
			</p>
			{#if link}
				<Icon icon={link.startsWith("https://") ? externalLink : arrowRight} class="h-[1.5rem] w-[1.5rem] shrink-0" />
			{/if}
		</div>
	</div>

	{#if backgroundImage}
		{#if typeof backgroundImage === "string"}
			<StaticPicture
				image={backgroundImage}
				alt={title}
				height={580}
				width={580}
				imgClass="absolute h-full inset-0 object-cover transition-all duration-300 {backgroundImageOverlay &&
				fixedBgOnMobile
					? `${link ? 'group-hover:lg:opacity-100' : 'group-hover:opacity-100'} opacity-100 lg:opacity-0`
					: `${link ? 'group-hover:lg:opacity-100' : 'group-hover:opacity-100'} opacity-0`}"
			/>
		{:else}
			<DynamicPicture
				bucket="storage"
				image={backgroundImage}
				alt={title}
				height={580}
				width={580}
				imgClass="absolute h-full inset-0 object-cover transition-all duration-300 {backgroundImageOverlay &&
				fixedBgOnMobile
					? `${link ? 'group-hover:lg:opacity-100' : 'group-hover:opacity-100'} opacity-100 lg:opacity-0`
					: `${link ? 'group-hover:lg:opacity-100' : 'group-hover:opacity-100'} opacity-0`}"
			/>
		{/if}
		{#if darkFilter}
			<div
				class="absolute inset-0 bg-black opacity-40 transition-all duration-300 lg:bg-transparent lg:group-hover:bg-black lg:group-hover:opacity-40"
			></div>
		{/if}
	{/if}
</a>
